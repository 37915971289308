import { ref } from "vue";
import TravelRoutesService from "@/services/pages/TravelRoutesService";
import VehicleService from "@/services/pages/VehicleService";
import DriverService from "@/services/pages/DriverService";
import TicketTypeService from "@/services/pages/TicketTypeService";
import StationService from "@/services/pages/StationService";
import { currentStation, userInfos, getAllStationsUser } from "@/state/api/userState";
import SettingsService from "../pages/SettingsService";

export const statusOK = ref(true);

export const enableNextStepButton = ref(false); // active le bouton Next dans l'assistance de configuration popup

export const wizardInit = ref(false); // prepare a modal page to be open
export const wizardComplete = ref(false); // use to open a success modal page
export const wizardClose = ref(false); // use to close a success modal page

export const recapitulationInfo: any = ref({
	vehicle: {
		label: "véhicule",
		status: 1,
		step: 1,
		routeName: "workspace-autopark-vehicle",
		query: null
	},
	driver: {
		label: "chauffeur",
		status: 1,
		step: 2,
		routeName: "workspace-human-resources-staff",
		query: { tab: "driver" }
	},
	travelRoute: {
		label: "itinéraire",
		status: 1,
		step: 3,
		routeName: "workspace-planning-routes-and-rates",
		query: { tab: "itinary" }
	},
	ticketType: {
		label: "type de ticket",
		status: 1,
		step: 4,
		routeName: "workspace-planning-routes-and-rates",
		query: { tab: "ticketType" }
	},
	// stationService: { label: "véhicule", status: 1},
});

export const updateRecapilationInfoIfNecessary = async (param: string) => {
	if (!statusOK.value) {
		recapitulationInfo.value[param].status = 1;
		enableNextStepButton.value = true;
		await launchPrerequisitesService();
	}
};

export const resetDataPrerequisitesService = () => {
	wizardInit.value = false;
	wizardComplete.value = false;
	wizardClose.value = false;
	enableNextStepButton.value = false;
	statusOK.value = false;
};

export const launchPrerequisitesService = async (_currentStation:any = null) => {
	console.log("_currentStation: ", _currentStation);
	const stationID = _currentStation ? _currentStation.key : currentStation.value.key
	console.log('stationID: ', stationID);
	// const travelRouteResp: any = await TravelRoutesService.getAll(
	// 	"?station=" + stationID
	// );
	// const vehicleResp: any = await VehicleService.getAll("?station=" + stationID);
	// const dvehicleResp: any = await DriverService.getAll("?station=" + stationID);
	// const tTypeResp: any = await TicketTypeService.getAll("?station=" + stationID + "&sss=");
	const settingsdata:any = await SettingsService.get(
		`?station=${stationID}&labels=has_travel_routes,has_vehicles,has_drivers,has_ticket_types`
	);

	if (settingsdata) {
		const hasTravelRoutes: any = Object.prototype.hasOwnProperty.call(
			settingsdata,
			"has_travel_routes"
		) ? settingsdata.has_travel_routes : 'false';
		const hasVehicles: any = Object.prototype.hasOwnProperty.call(settingsdata, "has_vehicles")
			? settingsdata.has_vehicles
			: "false";;
		const hasDrivers: any = Object.prototype.hasOwnProperty.call(
			settingsdata,
			"has_drivers"
		)
			? settingsdata.has_drivers
			: "false";;
		const hasTicketTypes: any = Object.prototype.hasOwnProperty.call(settingsdata, "has_ticket_types")
		? settingsdata.has_ticket_types
		: "false";

		console.log('hasTicketTypes: ', hasTicketTypes);
		console.log("settingsdata: ", settingsdata);
		// const eStationResp:any = await StationService.getAll("?station=" + stationID)

		if (
			hasTravelRoutes == "false" ||
			hasVehicles == "false" ||
			hasDrivers == "false" ||
			hasTicketTypes == "false" /*|| eStationResp.length == 0*/
		) {
			statusOK.value = false;

			if (hasTravelRoutes == "false") recapitulationInfo.value.travelRoute.status = 0;
			if (hasVehicles == "false") recapitulationInfo.value.vehicle.status = 0;
			if (hasDrivers == "false") recapitulationInfo.value.driver.status = 0;
			if (hasTicketTypes == "false") recapitulationInfo.value.ticketType.status = 0;
		} else {
			statusOK.value = true;
			// fin de l'assistant de configuration
			wizardComplete.value = true;
		}
	} else {
		statusOK.value = false;

		recapitulationInfo.value.travelRoute.status = 0;
		recapitulationInfo.value.vehicle.status = 0;
		recapitulationInfo.value.driver.status = 0;
		recapitulationInfo.value.ticketType.status = 0;
	}
};

export default {
	run: async (currentStation = null) => launchPrerequisitesService(currentStation),
};
