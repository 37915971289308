import api from "@/api";

const get = async (filter?: any, isLoading = false) => {
	isLoading = true;
	let settingsData;
	await api()
		.settings.get(filter == null ? "" : filter)
		.then((res: any) => {
			settingsData = res.data;
		})
		.catch((err: any) => {
			console.log(err);
		});
	isLoading = false;
	return settingsData;
};

export default {
	get
};
