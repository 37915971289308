// Services
import callAPI from "../callApi";
import path from "../variables";

const User = {
	getAll: async () => await callAPI("GET", `${path.base}/users`, "", "getting all users"),
	getUserInfosWithID: async (id: any) =>
		await callAPI("GET", `${path.base}/users/${id}`, "", "getting user infos with ID"),
	getUserInfos: async () => await callAPI("GET", `${path.base}/user/infos`, "", "retrieving user details"),
	editUserInfos: async (id: string, data: any) =>
		await callAPI("PUT", `${path.base}/users/${id}`, data, "updating user infos"),
	editPassword: async (data: any) =>
		await callAPI("POST", `${path.base}/user/password`, data, "updating password"),
};

export default User;
