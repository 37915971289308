<script lang="ts" setup>

</script>

<template>
	<div class="flex flex-row w-full" style="height: calc(100vh)">
		<!-- Left Section : Menu -->
		<div class="w-[350px]" id="menu">
			<AppMenu />
		</div>

		<!-- Right Section -->
		<div class="flex flex-col w-full px-4 pt-4 pb-0" style="height: 100vh">
			<!-- Header -->
			<div class="" style="height: 4rem" id="header">
				<AppHeader />
			</div>
			<!-- Main Content -->
			<div class="flex flex-col w-full" style="height: calc(100vh - 4rem)">
				<!-- Main Content -->
				<div class="relative w-full h-full">
					<div class="absolute z-10 w-full h-full opacity-70"></div>
					<div class="relative z-20 p-4">
						<slot></slot>
					</div>
				</div>
				<!-- Footer -->
				<div class="flex items-center" style="height: 3rem" id="footer">
					<AppFooter />
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	//
</style>
