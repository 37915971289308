import { ref, watchEffect } from "vue";
import { useStorage } from "@vueuse/core";
import { useCookies } from "@vueuse/integrations/useCookies";
import api from "@/api";
import { axios } from "@/services";
import { resetUserInfos } from "./userState";
import { detectPlatform, getExpDiff, validToken } from "@/utils";
import dayjs from "dayjs";
import { resetDataPrerequisitesService } from "@/services/global/prerequisitesService";

type Status =
	| "initial"
	| "loading"
	| "error"
	| "connected"
	| "disconnected"
	| "registered"
	| "verifying"
	| "verified"
	| "reseting"
	| "reseted"
	| "refreshing"
	| "refreshed"
	| "generating";

type SecureKey = {
	key: string;
	key_res: string;
	exp: number;
	exp_in: number;
};

/**
 * Datas
 */
export const cookies = useCookies(["session"]);
export const authStatus = ref<Status>("initial");
export const isLoggedIn = ref<boolean>(false);
export const authToken = useStorage<SecureKey>("session", {
	key: "",
	key_res: "",
	exp: 0,
	exp_in: 0,
});
const platform = detectPlatform();
const refreshTimer = ref<any>(null);
const testTimer = ref<any>(null);

/**
 * Methods
 */

export function updateAuthStatus(status: Status) {
	authStatus.value = status;
	switch (status) {
		case "initial":
			isLoggedIn.value = false;
			return;
		case "loading":
			isLoggedIn.value = false;
			return;
		case "connected":
			isLoggedIn.value = true;
			return;
		case "refreshing":
			isLoggedIn.value = true;
			return;
		case "disconnected":
			isLoggedIn.value = false;
			// Remove token in cookie session
			cookies.remove("session", {
				path: "/",
			});
			// Reset token in local storage
			authToken.value = { key: "", key_res: "", exp: 0, exp_in: 0 };
			// Disable token auto refresher
			if (refreshTimer.value != null) {
				clearTimeout(refreshTimer.value);
			} else {
				refreshTimer.value = null;
			}
			// Reset request authorisation header
			axios.defaults.headers.common.Authorization = "";
			return;
		default:
			return;
	}
}

export function authLogin(payload: any) {
	return new Promise<any>((resolve, reject) => {
		// If user is already logged in notify and exit
		if (isLoggedIn.value == true) {
			console.log("%c Login Attempt ", "color: #95B46A;", "You are already logged in!");
			const error = {
				type: "loginAttempts",
				data: "Vous êtes actuelements connecté !",
			};
			reject(error);
		}

		// Change status
		authStatus.value = "loading";

		// Send login request
		api()
			.auth.login({
				email: payload.email,
				password: payload.password,
			})
			.then((res: any) => {
				// Save token
				saveToken(res);

				resolve(res.data);
			})
			.catch((err: any) => {
				// Change status
				authStatus.value = "error";
				reject(err);
			});
	});
}

export function authRegister(payload: any) {
	return new Promise<any>((resolve, reject) => {
		// Change status
		authStatus.value = "loading";

		// Send login request
		api()
			.auth.register(payload)
			.then((res: any) => {
				resolve(res);
			})
			.catch((err: any) => {
				// Change status
				authStatus.value = "error";
				reject(err);
			});
	});
}

export function authResendEmail(payload: any) {
	return new Promise<any>((resolve, reject) => {
		api()
			.auth.resendEmail(payload)
			.then((res: any) => {
				resolve(res);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
}

export function authValidateAccount(payload: any) {
	return new Promise<any>((resolve, reject) => {
		// Change status
		authStatus.value = "verifying";

		// Send login request
		api()
			.auth.validateAccount(payload)
			.then((res: any) => {
				resolve(res);
			})
			.catch((err: any) => {
				// Change status
				authStatus.value = "error";
				reject(err);
			});
	});
}

export function authForgotPassword(payload: any) {
	return new Promise<any>((resolve, reject) => {
		// Change status
		authStatus.value = "loading";

		// Send login request
		api()
			.auth.forgotPassword(payload)
			.then((res: any) => {
				resolve(res.data);
				authStatus.value = "verified";
			})
			.catch((err: any) => {
				// Change status
				authStatus.value = "error";
				reject(err);
			});
	});
}

export function authVerifyCode(payload: any) {
	return new Promise<any>((resolve, reject) => {
		// Change status
		authStatus.value = "verifying";

		// Send login request
		api()
			.auth.verifyCode(payload)
			.then((res: any) => {
				resolve(res.data);
				authStatus.value = "verified";
			})
			.catch((err: any) => {
				// Change status
				authStatus.value = "error";
				reject(err);
			});
	});
}

export function authResetPassword(payload: any) {
	return new Promise<any>((resolve, reject) => {
		// Change status
		authStatus.value = "reseting";

		// Send login request
		api()
			.auth.resetPassword(payload)
			.then((res: any) => {
				resolve(res.data);
				authStatus.value = "reseted";
			})
			.catch((err: any) => {
				// Change status
				authStatus.value = "error";
				reject(err);
			});
	});
}

export async function authLogout() {
	// reinitialisation des variable du service d'assistance
	resetDataPrerequisitesService();

	return new Promise<boolean>((resolve, reject) => {
		let token: any;
		const logoutAction = () => {
			updateAuthStatus("disconnected");
			resetUserInfos();

			resolve(true);
		};

		const doLogout = () => {
			api()
				.auth.logout()
				.then(() => {
					logoutAction();
				})
				.catch((err: any) => {
					reject(err);
				});
		};

		if (
			axios.defaults.headers.common.Authorization != "" ||
			axios.defaults.headers.common.Authorization != undefined
		) {
			token = validToken();
			if (token.status == "valid") {
				// Configuring global request header with token
				axios.defaults.headers.common.Authorization = `Bearer ${token.key}`;
				// Logout user
				doLogout();
			} else if (token.status == "invalid") {
				logoutAction();
			}

			if (refreshTimer.value != null) {
				clearTimeout(refreshTimer.value);
				// clearInterval(testTimer.value);
			}
		}
	});
}

function refreshToken(token: any) {
	updateAuthStatus("refreshing");
	api()
		.auth.refreshToken({ refresh_token: token.key_res })
		.then((response: any) => {
			// Change auth status to signal token refreshed
			updateAuthStatus("refreshed");
			// Update token
			saveToken(response.data, false).then(() => {
				// Change auth status to signal user connected
				updateAuthStatus("connected");
			});
		})
		.catch(async (err: any) => {
			// isLoggedIn.value = false;
			await authLogout();
			document.location.href = "/auth/login";
		});
}

export function activateTokenRefreshTimer(token: any) {
	const refreshTime: any = process.env.VUE_APP_REFRESH_TIME_REDUCTION;
	const timeout = getExpDiff(token.exp) * 1000 - refreshTime;
	if (refreshTimer.value != null) {
		clearTimeout(refreshTimer.value);
		// clearInterval(testTimer.value);
	}
	if (timeout <= 0) {
		refreshToken(token);
	} else {
		refreshTimer.value = setTimeout(() => {
			refreshToken(token);
		}, timeout);

		// let b = timeout;
		// testTimer.value = setInterval(() => {
		// 	console.log('remaing  ------------------ : ', (b = (b - 5000 ))/ 1000);
		// }, 5000)
	}
}

export function saveToken(response: any, saveRefreshToken = true) {
	console.log("response: ", response);
	return new Promise<boolean>((resolve, reject) => {
		// We create the expiration date in unix timestamp
		const exp = dayjs().add(response.auth.expires_in, "second").unix();
		// We create the object which will contain the token
		const token: any = {
			key: response.auth.access_token,
			// key_res: response.auth.refresh_token,
			exp: exp,
			exp_in: response.auth.expires_in,
		};

		// Save the refreshToken if it is retrieving whithout refreshToken() function, else, we use the old refresh token
		if (saveRefreshToken) {
			token.key_res = response.auth.refresh_token;
		} else {
			token.key_res = cookies.get("session").key_res;
		}

		// Configuring global request header with token
		axios.defaults.headers.common.Authorization = `Bearer ${token.key}`;

		// We save the token
		if (
			platform.runtime != "cordova" &&
			platform.runtime != "electron" &&
			platform.browser != "safari"
		) {
			const maxAge: any = process.env.VUE_APP_COOKIE_MAXAGE;
			const secure: any = (process.env.VUE_APP_COOKIE_SECURE ?? true) as boolean;
			cookies.set("session", token, {
				path: "/",
				secure: false,
				httpOnly: false,
				maxAge: parseInt(maxAge),
			});
		} else {
			authToken.value = token;
		}

		activateTokenRefreshTimer(token);
		resolve(true);
	});
}
