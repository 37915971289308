// Services
import callAPI from "../callApi";
import path from "../variables";

const Settings = {
	get: async (filter:any) =>
		await callAPI(
			"GET",
			`${path.base}/agency/settings${filter ? filter : ''}`,
			"",
			"getting specific Settings"
		),
};

export default Settings;
