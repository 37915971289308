// Services
import callAPI from "../callApi";
import path from "../variables";

const packages = {
	getAll: (params: string) =>
		callAPI(
			"GET",
			`${path.base}/operation/packages${params ? params : ""}`,
			"",
			"getting all packages"
		),
	availableToPickup: (params: string) =>
		callAPI(
			"GET",
			`${path.base}/operation/packages/pickup${params ? params : ""}`,
			"",
			"getting all packages to pickup"
		),
	pickup: (params: string) =>
		callAPI(
			"PUT",
			`${path.base}/operation/packages/pickup${params ? params : ""}`,
			"",
			"pickup a package"
		),
	changeStatus: (data:any, params: string) =>
		callAPI(
			"PUT",
			`${path.base}/operation/packages/status${params ? params : ""}`,
			data,
			"changing status package"
		),
	create: (data: any, params: string) =>
		callAPI(
			"POST",
			`${path.base}/operation/packages${params ? params : ""}`,
			data,
			"create new package"
		),
};

export default packages;
