// Services
import callAPI from "../callApi";
import path from "../variables";

const expenses = {
	getAll: (param: string) =>
		callAPI(
			"GET",
			`${path.base}/operation/expenses${param ? param : ""}`,
			"",
			"getting all expenses operations"
		),
	create: (data: any, param: string) =>
		callAPI(
			"POST",
			`${path.base}/operation/expenses${param ? param : ""}`,
			data,
			"create expense operation"
		),
	update: (data: any, param: string) =>
		callAPI(
			"PUT",
			`${path.base}/operation/expenses/${param}`,
			data,
			"updating expense operation"
		),
	getAllTypes: (data: any, param: string) =>
		callAPI(
			"GET",
			`${path.base}/planning/expense-types${param ? param : ""}`,
			data,
			"getting all Expenses Types"
		),
};


export default expenses;
