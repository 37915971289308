// Services
import callAPI from "../callApi";
import path from "../variables";

const Expeditions = {
	getAll: (filter?: any) =>
		callAPI(
			"GET",
			`${path.base}/planning/expeditions${filter ? filter : ""}`,
			"",
			"getting all expeditions"
		),
	getAllPackagesReadyToExpedition: (params?: any) =>
		callAPI(
			"GET",
			`${path.base}/operation/packages/transfers${params ? params : ""}`,
			"",
			"getting all Packages Ready To Expedition"
		),
	makePackageToCurrentExpedition: (data: any, params?: any) =>
		callAPI(
			"PUT",
			`${path.base}/operation/packages/transfers${params ? params : ""}`,
			data,
			"making Packages To Current Expedition"
		),
	removePackageToCurrentExpedition: (data: any, params?: any) =>
		callAPI(
			"PUT",
			`${path.base}/operation/packages/transfers${params ? params : ""}`,
			data,
			"making Packages To Current Expedition"
		),
	getAllPackagesEngagedInAnExpedition: (params?: any) =>
		callAPI(
			"GET",
			`${path.base}/operation/packages/transfered${params ? params : ""}`,
			"",
			"getting All Packages Engaged In a Current Expedition"
		),
	detail: (id: number) =>
		callAPI("GET", `${path.base}/planning/expeditions/${id}`, "", "getting a specific expedition"),
	create: (data: any, params: string) =>
		callAPI(
			"POST",
			`${path.base}/planning/expeditions${params ? params : ""}`,
			data,
			"creating expedition"
		),
	update: (data: any, id: number) =>
		callAPI("PUT", `${path.base}/planning/expeditions/${id}`, data, "updating expedition"),
	changeExpeditionStatus: (data: any, param: string) =>
		callAPI(
			"PUT",
			`${path.base}/operation/expeditions/status-management${param ? param : ''}`,
			data,
			"updating expedition status"
		),
};

export default Expeditions;
