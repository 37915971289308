// Services
import callAPI from "../callApi";
import path from "../variables";

const route = {
	getAll: () => callAPI("GET", `${path.base}/planning/aa`, "", "getting all route dockets"),
};

const travel = {
	getAll: (filter: string) =>
		callAPI(
			"GET",
			`${path.base}/reporting/traveldockets${filter ? filter : ""}`,
			"",
			"getting all travel dockets"
		),
	details: (param: string) =>
		callAPI(
			"GET",
			`${path.base}/reporting/traveldockets/${param}`,
			"",
			"getting details of travel docket"
		),
	updateExpenseReport: (data: any) =>
		callAPI(
			"PUT",
			`${path.base}/operation/travel-docket/expense-report/update`,
			data,
			"updating Expense Report of current travel dockets"
		),
};

export default { travel, route };
